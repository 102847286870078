/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import HeaderHeightProvider from 'context/HeaderHeightContext';
import ScrollProvider from 'context/ScrollContext';
import SearchProvider from 'context/SearchContext';
import WindowWidthProvider from 'context/WindowWidthContext';

export const wrapRootElement = ({ element }) => (
    <WindowWidthProvider>
        <SearchProvider>
            <ScrollProvider>
                <HeaderHeightProvider>
                    {element}
                </HeaderHeightProvider>
            </ScrollProvider>
        </SearchProvider>
    </WindowWidthProvider>
);
