import React, { useState, useEffect, createContext } from 'react';
import { globalHistory } from '@reach/router';

export const SearchContext = createContext();

const SearchProvider = ({ children }) => {
    const { location } = globalHistory;
    const params = new URLSearchParams( location.search );
    const [searchQuery, setSearchQuery] = useState( params.get('q') );

    useEffect(() => {
        return globalHistory.listen( ({ location }) => {
            if ( /^\/search/.test(location.pathname) ) { // is the search page
                const params = new URLSearchParams(location.search);
                setSearchQuery( params.get('q') );
            } else {
                setSearchQuery('');
            }
        });
    });

    return (
        <SearchContext.Provider value={searchQuery}>
            {children}
        </SearchContext.Provider>
    );
}

export default SearchProvider;
