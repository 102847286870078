import React, { useState, useEffect, useContext, createContext } from 'react';

import { ScrollContext } from 'context/ScrollContext';
import { WindowWidthContext } from 'context/WindowWidthContext';
import { header_height_at_top, header_height_scrolled } from 'utility/header-height';
import { breakpoints } from 'utility/screen-width';

export const HeaderHeightContext = createContext();

const HeaderHeightProvider = ({ children }) => {
    const [headerHeight, setHeaderHeight] = useState(header_height_at_top);
    const [widthBelowThreshold, setWidthBelowThreshold] = useState(false);
    const pageY = useContext(ScrollContext);
    const windowWidth = useContext(WindowWidthContext);

    useEffect(() => {
        if (widthBelowThreshold && windowWidth >= breakpoints.xs) {
            setWidthBelowThreshold(false);
        } else if (!widthBelowThreshold && windowWidth < breakpoints.xs) {
            setWidthBelowThreshold(true);
        }
    }, [windowWidth]);

    useEffect(() => {
        calculateHeaderHeight();
    }, [pageY, widthBelowThreshold]);

    const calculateHeaderHeight = () => {
        if (widthBelowThreshold) {
            setHeaderHeight(header_height_scrolled);
            return;
        }

        if (headerHeight !== header_height_scrolled && pageY >= header_height_scrolled) {
            setHeaderHeight(header_height_scrolled);
        } else if (headerHeight !== header_height_at_top && pageY <= header_height_scrolled) {
            setHeaderHeight(header_height_at_top);
        }
    }

    return (
        <HeaderHeightContext.Provider value={headerHeight}>
            {children}
        </HeaderHeightContext.Provider>
    );
}

export default HeaderHeightProvider;
