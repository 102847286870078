import React, { useState, useEffect, useContext, createContext } from 'react';

export const WindowWidthContext = createContext();

const WindowWidthProvider = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(null);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', windowResizeHandler);

        return () => {
            window.removeEventListener('resize', windowResizeHandler);
        };
    });

    const windowResizeHandler = () => {
        setWindowWidth(window.innerWidth);
    }

    return (
        <WindowWidthContext.Provider value={windowWidth}>
            {children}
        </WindowWidthContext.Provider>
    );
}

export default WindowWidthProvider;
