import React, { useState, useEffect, createContext } from 'react';

export const ScrollContext = createContext();

const ScrollProvider = ({ children }) => {
    const [pageY, setPageY] = useState(null);

    useEffect(() => {
        scrollHandler();
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    });

    const scrollHandler = () => {
        setPageY(window.pageYOffset);
    }

    return (
        <ScrollContext.Provider value={pageY}>
            {children}
        </ScrollContext.Provider>
    );
}

export default ScrollProvider;
